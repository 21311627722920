import NoAccountControls from '@components/DtpConnectAccountWizard/common/NoAccountControls'
import TooltipLabel from '@components/DtpConnectAccountWizard/common/TooltipLabel'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import DtpTextField from '@components/DtpTextField/DtpTextField'
import { FormSteps } from '@enums/authorization'
import { Box, Button, Typography } from '@mui/material'
import { useMask } from '@react-input/mask'
import { FormikErrors, FormikTouched } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IAccountNumberProps {
  goTo: (step: string) => void
  gpnr: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  error: FormikErrors<string>
  touched: FormikTouched<boolean>
}

const AccountNumber = ({ goTo, gpnr, error, onChange, onBlur, touched }: IAccountNumberProps) => {
  const { t } = useTranslation()
  const accountNumberRef = useMask({ mask: '_ ___ ___ ___', replacement: '_' })

  return (
    <Wrapper>
      <Box mb={4}>
        <Typography variant="heading3">{t('authorization.connect_account')}</Typography>
        <Typography variant="bodyRegular">{t('authorization.authorization_explanation')}</Typography>
      </Box>

      <DtpTextField
        value={gpnr}
        onChange={onChange}
        onBlur={onBlur}
        name="gpnr"
        inputRef={accountNumberRef}
        label={
          <TooltipLabel
            label={t('authorization.auth_customer_number')}
            tooltipLabel={t('authorization.tooltip_authorization_customer_number')}
          />
        }
        inputMode="tel"
        helperText={touched ? error : undefined}
        error={Boolean(error)}
        placeholder="x xxx xxx"
        fullWidth
      />

      <Box mt={4}>
        <Button size="large" onClick={() => goTo(FormSteps.ACCESS_CODE_STEP)} disabled={!!error || !gpnr.length}>
          {t('authorization.connect_account')}
        </Button>
        <NoAccountControls onClick={() => goTo(FormSteps.SUPPORT)} />
      </Box>
    </Wrapper>
  )
}

export default AccountNumber
