import { Wizard } from '@components/Wizard/Wizard'
import { FormSteps } from '@enums/authorization'
import useAuthorizeUser from '@hooks/useAuthorizeUser/useAuthorizeUser'
import { Dialog, styled } from '@mui/material'

import AccessCode from './steps/AccessCode/AccessCode'
import AccountNumber from './steps/AccountNumber/AccountNumber'
import ContactSupport from './steps/ContactSupport/ContactSupport'
import RequestNewCode from './steps/RequestNewCode/RequestNewCode'

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: theme.spacing(8, 6),
    margin: 0,
    maxHeight: '100%',
    maxWidth: 720,
    width: '100%',
    height: 'initial',
    borderRadius: '24px',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      padding: theme.spacing(4, 2),
      borderRadius: 0,
    },
  },
  '& .MuiBox-root:nth-of-type(2)': {
    height: '100%',
  },
}))

const DtpConnectAdditionalAccountWizard = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { authOptions, isLoadingAuthOptions, authorizeUserForm, isAuthorizingAccount, requestAccessCode } =
    useAuthorizeUser()

  return (
    <CustomDialog open={open} onClose={onClose}>
      <Wizard>
        <Wizard.Step
          id={FormSteps.ACCOUNT_NUMBER_STEP}
          component={AccountNumber}
          onChange={authorizeUserForm.handleChange}
          onBlur={authorizeUserForm.handleBlur}
          gpnr={authorizeUserForm.values.gpnr}
          error={authorizeUserForm.errors.gpnr}
          touched={authorizeUserForm.touched.gpnr}
          onClose={onClose}
        />
        <Wizard.Step
          id={FormSteps.ACCESS_CODE_STEP}
          component={AccessCode}
          onChange={authorizeUserForm.handleChange}
          onBlur={authorizeUserForm.handleBlur}
          onSubmit={authorizeUserForm.handleSubmit}
          evidence={authorizeUserForm.values.evidence}
          error={authorizeUserForm.errors.evidence}
          touched={authorizeUserForm.touched.evidence}
          isLoading={isAuthorizingAccount}
          onClose={onClose}
        />
        <Wizard.Step
          id={FormSteps.REQUEST_ACCESS_CODE}
          component={RequestNewCode}
          isLoading={isLoadingAuthOptions}
          authOptions={authOptions}
          requestAccessCode={requestAccessCode}
          onClose={onClose}
        />
        <Wizard.Step
          id={FormSteps.SUPPORT}
          component={ContactSupport}
          accountNumber={authorizeUserForm.values.gpnr}
          onClose={onClose}
        />
      </Wizard>
    </CustomDialog>
  )
}

export default DtpConnectAdditionalAccountWizard
