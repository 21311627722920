import AccessCodeInput from '@components/DtpConnectAccountWizard/common/AccessCodeInput'
import { FormSteps } from '@enums/authorization'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Button, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material'
import { FormikTouched, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IAccessCodeProps {
  goTo: (step: string) => void
  evidence: string
  error: FormikErrors<string>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  touched: FormikTouched<boolean>
  isLoading?: boolean
  onClose?: () => void
}

const AccessCode = ({
  goTo,
  evidence,
  error,
  onChange,
  touched,
  onSubmit,
  onBlur,
  isLoading,
  onClose,
}: IAccessCodeProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <form onSubmit={onSubmit} style={{ height: '100%' }}>
      <Stack height="100%">
        <Box mb={4}>
          <Stack mb={4} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="heading3" sx={{ my: 0, mt: 0.5 }}>
              {t('authorization.enter_access_code')}
            </Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="bodyRegular">{t('authorization.tooltip_access_code')}</Typography>
        </Box>

        <AccessCodeInput
          value={evidence}
          onChange={onChange}
          onBlur={onBlur}
          helperText={touched ? error : undefined}
          error={touched && Boolean(error)}
        />
        <Box mt={3}>
          <Button
            variant="text"
            disableTouchRipple
            size="large"
            sx={{ p: '0 !important' }}
            onClick={() => goTo(FormSteps.REQUEST_ACCESS_CODE)}
          >
            {t('authorization.request_new_code')}
          </Button>
        </Box>

        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" mt={{ xs: 'auto', sm: 4 }} gap={2}>
          <Button variant="contained" size="large" type="submit" fullWidth={isMobile} disabled={!!error || isLoading}>
            {t('authorization.complete')}
          </Button>
          <Button
            variant="outlined"
            size="large"
            fullWidth={isMobile}
            onClick={() => goTo(FormSteps.ACCOUNT_NUMBER_STEP)}
            disabled={isLoading}
          >
            {t('back')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default AccessCode
