import AccessCodeInput from '@components/DtpConnectAccountWizard/common/AccessCodeInput'
import Wrapper from '@components/DtpConnectAccountWizard/common/Wrapper'
import { FormSteps } from '@enums/authorization'
import { Box, Stack, Button, Typography } from '@mui/material'
import { FormikTouched, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IAccessCodeProps {
  goTo: (step: string) => void
  evidence: string
  error: FormikErrors<string>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  touched: FormikTouched<boolean>
  isLoading?: boolean
}

const AccessCode = ({ goTo, evidence, error, onChange, touched, onSubmit, onBlur, isLoading }: IAccessCodeProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <Box mb={4}>
          <Typography variant="heading3">{t('authorization.enter_access_code')}</Typography>
          <Typography variant="bodyRegular">{t('authorization.enter_code_explanation')}</Typography>
        </Box>

        <AccessCodeInput
          value={evidence}
          onChange={onChange}
          onBlur={onBlur}
          helperText={touched ? error : undefined}
          error={touched && Boolean(error)}
        />

        <Box mt={3}>
          <Button
            variant="text"
            disableTouchRipple
            size="large"
            sx={{ p: '0 !important' }}
            onClick={() => goTo(FormSteps.REQUEST_ACCESS_CODE)}
          >
            {t('authorization.request_new_code')}
          </Button>
          <Stack direction="row" alignItems="center" mt={4} gap={1}>
            <Button variant="contained" size="large" type="submit" disabled={!!error || isLoading}>
              {t('authorization.complete')}
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={() => goTo(FormSteps.ACCOUNT_NUMBER_STEP)}
              disabled={isLoading}
            >
              {t('back')}
            </Button>
          </Stack>
        </Box>
      </form>
    </Wrapper>
  )
}

export default AccessCode
