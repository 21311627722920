export enum AuthOptions {
  SMS = 'mobileNumber',
  EMAIL = 'invoiceEmail',
}

export enum FormSteps {
  ACCOUNT_NUMBER_STEP = 'account-number',
  ACCESS_CODE_STEP = 'access-code',
  REQUEST_ACCESS_CODE = 'request-access-code',
  SUPPORT = 'contact-support',
  AUTOMATIC_AUTH = 'automatic-authorization',
  AUTOMATIC_AUTH_OTP = 'automatic-authorization-otp',
}
