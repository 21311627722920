import { PhoneIcon } from '@assets/icons'
import MethodSelectCard from '@components/DtpConnectAccountWizard/common/MethodSelectCard'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography, IconButton, Stack, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'

const ContactSupport = ({
  goBack,
  accountNumber,
  onClose,
}: {
  goBack: () => void
  accountNumber?: string
  onClose?: () => void
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  return (
    <Stack height="100%">
      <Stack mb={4} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="heading3" sx={{ my: 0, mt: 0.5 }}>
          {t('authorization.no_contact_details_available')}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography variant="bodyRegular">
        <Trans
          i18nKey="authorization.no_self_service_for_customer_number_explanation"
          values={{
            workTime: t('authorization.opening_hours_contact_center'),
          }}
          components={{
            strong: <Typography component="span" variant="bodyRegularEmphasized" fontWeight="700" />,
          }}
        />
      </Typography>
      <MethodSelectCard
        descriptionColor="primary"
        icon={<PhoneIcon />}
        title={t('authorization.call_us')}
        description={'0800 88 77 66'}
      />
      <Box mb={4}>
        <Typography variant="bodyRegular" sx={{ my: 0.5 }}>
          <Trans
            i18nKey="authorization.prepare_customer_number"
            values={{
              customerNumber: t('authorization.auth_customer_number'),
            }}
            components={{
              strong: <Typography component="span" variant="bodyRegularEmphasized" fontWeight="700" />,
            }}
          />
        </Typography>
        <Typography variant="bodyRegularEmphasized" fontWeight="500" sx={{ my: 0 }}>
          {accountNumber}
        </Typography>
      </Box>
      <Box mt={'auto'}>
        <Button variant="outlined" size="large" fullWidth={isMobile} onClick={goBack}>
          {t('button_close')}
        </Button>
      </Box>
    </Stack>
  )
}

export default ContactSupport
